import axios from 'axios';

export default {
  name: 'appsettings',
  data() {
    return {
      rules: [
        (v) => !!v || 'This field is required',
        (v) => (v && v >= 6) || 'Password should be longer than 6 characters',
      ],
      alert: false,
      alertSuccess: false,
      alertMessage: '',
      accounts: undefined,
      currentFile: null,
      backgrounds: undefined,
      icons: undefined,
      buttonText: '',
      radioGroupIcons: 1,
      radioGroupBackgrounds: 1,
      content: undefined,
      selected: null,
      displayContentPiece: false,
      color: '#1976D2FF',
      mask: '!#XXXXXXXX',
      menu: false,
      contentTextSwe: null,
      contentTextEng: null,
      checkbox: true,
      dialog: false,
      courses: undefined,
      selectCourse: [],
      activeCourses: false,
    };
  },
  watch: {
    alert(newVal) {
      if (newVal) {
        setTimeout(() => { this.alert = false; }, 3000);
      }
    },
  },
  mounted() {
    this.loadBackgrounds();
    this.loadContents();
    this.loadIcons();
    this.loadCourses();
    this.loadAccounts();
  },
  computed: {
    changedMessage() {
      if (this.selected !== null) {
        this.displayContentPiece = true;
        for (const currContent of this.content) {
          if (currContent.id === this.selected) {
            this.contentTextSwe = currContent.text;
            this.contentTextEng = currContent.text;
          }
        }
        console.log(this.content);
        console.log(this.displayContentPiece);
        return this.selected;
      }

      console.log(this.displayContentPiece);
      return null;
    },
    swatchStyle() {
      const { color, menu } = this;
      return {
        backgroundColor: color,
        cursor: 'pointer',
        height: '30px',
        width: '30px',
        borderRadius: menu ? '50%' : '4px',
        transition: 'border-radius 200ms ease-in-out',
      };
    },
  },
  methods: {
    addSingleAccount(index) {
      this.accounts.push({
        username: '', password: '', created: undefined, role: 'User',
      });
    },
    removeSingleAccount(index) {
      const options = {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
      };
      axios.delete(`${process.env.VUE_APP_BACKENDURL}/accounts/${this.accounts[index].id}`,
        options).then((response) => {
        this.loadAccounts();
        console.log(this.accounts);
        this.accounts.splice(index, 1);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    updateAccount(index) {
      console.log(this.accounts[index]);
      const options = {
        headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
      };
      axios.post(`${process.env.VUE_APP_BACKENDURL}/accounts`,
        {
          Username: this.accounts[index].username,
          Password: this.accounts[index].password,
          Role: this.accounts[index].role,
        }, options).then((response) => {
        this.loadAccounts();
        this.alertMessage = 'User added!';
        this.alert = true;
        this.alertSuccess = true;
      })
        .catch((error) => {
          this.alert = true;
          this.alertSuccess = false;
          if (error.response.status === 401) {
            this.alertMessage = 'Anv�ndaren kunde inte l�ggas till. Sessionen tappad. Var god logga in igen.';
          } else if ('errors' in error.response.data) {
            this.alertMessage = error.response.data.errors.Password[0];
          } else if (error.response.status === 400) {
            this.alertMessage = error.response.data.message;
          }
          console.log(error.response);
        });
    },
    selectBox() {
      if (this.activeCourses) {
        for (const course in this.courses) {
          if (this.courses.hasOwnProperty(course)) { // eslint-disable-line no-prototype-builtins
            this.courses[course].active = false;
          }
        }
        this.selectCourse = [];
        this.activeCourses = false;
      } else {
        for (const course in this.courses) {
          if (this.courses.hasOwnProperty(course)) { // eslint-disable-line no-prototype-builtins
            if (!this.selectCourse.includes(this.courses[course].name)) {
              this.courses[course].active = true;
              this.selectCourse.push(this.courses[course].name);
            }
          }
        }
        this.activeCourses = true;
      }
    },
    reloadCourses() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/status/courses/reload`,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem('jwt')}` },
        })
        .then((response) => {
          console.log(response);
        });
      this.dialog = false;
    },
    selectFile(file) {
      this.currentFile = file;
    },
    selectedContent() {
      console.log('asd');
    },
    logout() {
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      this.$router.push('/');
    },
    postContents() {
      const formData = new FormData();
      const currContent = this.findCurrContent(this.selected);
      if (currContent !== null) {
        currContent.text = this.contentTextSwe;
        currContent.color = 'empty';
        console.log(this.contentTextSwe);
        console.log(currContent);
        formData.append('content', currContent);
        axios.post(`${process.env.VUE_APP_BACKENDURL}/api/content`,
          currContent,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
          }).then((response) => {
          this.alert = true;
          this.alertSuccess = true;
          this.alertMessage = '�ndringen �r genomf�rd';
          console.log(response);
        })
          .catch(() => {
            this.alert = true;
            this.alertSuccess = false;
            this.alertMessage = 'N�got gick fel vid �ndringen!';
            console.log('FAILURE!!');
          });
      }
    },
    findCurrContent(nameOfContent) {
      for (const currContent of this.content) {
        if (currContent.id === this.selected) {
          return currContent;
        }
      }
      return null;
    },
    uploadBackground() {
      const formData = new FormData();
      formData.append('files', this.currentFile);
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        }).then((response) => {
        this.loadBackgrounds();
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    uploadPdf() {
      const formData = new FormData();
      formData.append('files', this.currentFile);
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/upload/pdf`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        }).then((response) => {
        this.loadIcons();
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    uploadIcon() {
      const formData = new FormData();
      formData.append('files', this.currentFile);
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/uploadIcon`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        }).then((response) => {
        this.loadIcons();
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    loadAccounts() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/accounts`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      })
        .then((response) => {
          this.accounts = response.data;
          if (this.accounts.role === 'Admin') {
            this.accounts.role = 0;
          } else {
            this.accounts.role = 1;
          }
          console.log(this.accounts);
        });
    },
    loadContents() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content`)
        .then((response) => {
          this.content = response.data.contents;
        });
    },
    loadCourses() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/status/courses`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      })
        .then((response) => {
          this.courses = response.data;
          for (const item of this.courses) {
            if (item.active) {
              this.selectCourse.push(item.name);
            }
          }
          console.log(this.courses);
        });
    },
    loadIcons() {
      let counter = 0;
      this.radioGroupIcons = 0;
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/background`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      })
        .then((response) => {
          this.icons = response.data.icons;
          console.log(response);
          for (const item of this.icons) {
            console.log(item);
            if (item.in_use) {
              this.radioGroupIcons = counter;
              console.log(this.radioGroupIcons);
            } else {
              counter += 1;
            }
          }
        });
    },
    loadBackgrounds() {
      let counter = 0;
      this.radioGroupBackgrounds = 0;
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/background`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt')}`,
        },
      })
        .then((response) => {
          this.backgrounds = response.data.backgrounds;
          console.log(response);
          for (const item of this.backgrounds) {
            console.log(item);
            if (item.in_use) {
              this.radioGroupBackgrounds = counter;
              console.log(this.radioGroupBackgrounds);
            } else {
              counter += 1;
            }
          }
        });
    },
    setBackground() {
      const formData = new FormData();
      console.log(this.backgrounds[this.radioGroupBackgrounds].name);
      formData.append('backgroundName', this.backgrounds[this.radioGroupBackgrounds].name);
      formData.append('requestType', 'BG');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/background`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    setCourses() {
      console.log(this.courses);
      console.log(this.selectCourse);
      for (const course in this.courses) {
        if (this.courses.hasOwnProperty(course)) { // eslint-disable-line no-prototype-builtins
          this.courses[course].active = true;
          if (!this.selectCourse.includes(this.courses[course].name)) {
            this.courses[course].active = false;
          }
        }
      }
      axios.post(`${process.env.VUE_APP_BACKENDURL}/status/courses`,
        this.courses,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
          },
        }).then((response) => {
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    setIcon() {
      const formData = new FormData();
      console.log(this.icons[this.radioGroupIcons].name);
      formData.append('backgroundName', this.icons[this.radioGroupIcons].name);
      formData.append('requestType', 'IC');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/background`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            'Content-Type': 'multipart/form-data',
          },
        }).then((response) => {
        console.log(response);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    selectedItem() {
      console.log('test');
    },
  },
};
