import axios from 'axios';
import Vue from 'vue';
import VueRouter from 'vue-router';
import VueCodeMirror from 'vue-codemirror';
import { Datetime } from 'vue-datetime';
import 'codemirror/lib/codemirror.css';
import App from './App.vue';
import vuetify from './plugins/vuetify';

import createRouter from './router'; // eslint-disable-line import/no-named-as-default

Vue.use(VueCodeMirror);
Vue.use(VueRouter);
Vue.use(Datetime);

async function main() {
  const router = createRouter();

  const app = new Vue({
    router,
    vuetify,
    render: (h) => h(App),
  });
  app.$mount('#app');
}

main();
