<template>
  <v-card color="white"
            class="dsa"
            src="~/assets/colorest.png">
    <v-card-title class="headline">
    <v-img max-height="80"
            max-width="80"
            :src="icon" />
    <v-spacer></v-spacer>
    {{ content.Login_title }}
    <v-spacer></v-spacer>
    </v-card-title>
    <v-card-text>
      <v-text-field v-model="userInfo.userName"
                    :label="content.Login_text_1"
                    required></v-text-field>
    </v-card-text>
    <v-card-text>
      <v-text-field :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPass ? 'text' : 'password'"
                    class="text-green"
                    v-model="userInfo.password"
                    :label="content.Login_text_2"
                    @click:append="showPass = !showPass"
                    required></v-text-field>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#8bd9e4"
             @click="submitForm(userInfo)">
             {{ content.Login_button_1 }}
      </v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      showPass: false,
      userInfo: {
        userName: '',
        password: '',
      },
      icon: undefined,
    };
  },
  methods: {
    initiateIcon() {
      this.icon = `${process.env.VUE_APP_BACKENDURL}/api/uploadIcon`;
    },
  },
  mounted() {
    this.initiateIcon();
  },
  props: ['submitForm', 'content'],
};
</script>
