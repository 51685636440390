/* eslint no-lonely-if: "error" */
import axios from 'axios';
import UserAuthForm from '@/components/UserAuthForm.vue';

export default {
  name: 'login',
  data() {
    return {
      dialog: false,
      content: {
        Login_title: '',
        Login_text_1: '',
        Login_text_2: '',
        Login_button_1: '',
        Login_button_2: '',
        Register_title: '',
        Register_text_1: '',
        Register_text_2: '',
        Register_text_3: '',
        Register_text_4: '',
        Register_text_5: '',
        Register_text_6: '',
        Register_button_1: '',
        Register_button_2: '',
      },
      registerName: undefined,
      registerLastname: undefined,
      registerMail: undefined,
      registerBirth: undefined,
      registerCode: undefined,
      registerPhone: undefined,
      rules: {
        required: (value) => !!value || 'Krav.',
        counter: (value) => value.length <= 30 || 'Max 30 characters',
      },
    };
  },
  methods: {
    loginUser(loginInfo) {
      // console.log(loginInfo);
      // alert('You pressed this button');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/accounts/authenticate`,
        {
          Username: loginInfo.userName,
          Password: loginInfo.password,
        }).then((response) => {
        const isAdmin = response.data.role;
        const userResponse = {
          id: response.data.id,
          name: response.data.username,
          role: isAdmin,
        };
        const jsonUserResponse = JSON.stringify(userResponse);
        localStorage.setItem('user', jsonUserResponse);
        localStorage.setItem('jwt', response.data.token);
        if (localStorage.getItem('jwt') != null) {
          this.$emit('loggedIn');
          if (this.$route.params.nextUrl != null) {
            this.$router.push(this.$route.params.nextUrl);
          } else if (!(isAdmin)) {
            this.$router.push('appsettings');
          } else {
            this.$router.push('depotpage');
          }
        }
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
    },
    register() {
      const registerPerson = {
        name: this.registerName,
        lastname: this.registerLastname,
        birth: this.registerBirth,
        mail: this.registerMail,
        phonenumber: this.registerPhone,
        active: false,
      };
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/group/register`,
        {
          id: 6,
          person: registerPerson,
          code: this.registerCode,
        })
        .then((response) => {
          console.log(response);
          this.dialog = false;
        });
    },
    initiateContents() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content`)
        .then((response) => {
          for (let i = 0; i < response.data.contents.length; i += 1) {
            if (this.content[response.data.contents[i].id] === '') {
              this.content[response.data.contents[i].id] = response.data.contents[i].text;
            }
          }
          console.log(this.content);
        });
    },
  },
  mounted() {
    this.initiateContents();
  },
  components: {
    UserAuthForm,
  },
  beforeMount() {
    console.log(process.env.VUE_APP_BACKENDURL);
  },
};
