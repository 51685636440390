import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

import {
  DepotPage,
  LoginPage,
  AppSettings,
  Cases,
} from '../pages';

const routes = [
  {
    path: '/',
    name: 'loginpage',
    component: LoginPage,
    meta: {
      title: 'Test',
    },
  },
  {
    path: '/depotpage',
    name: 'depotpage',
    component: DepotPage,
    meta: {
      title: 'Test',
      requiresAuth: true,
    },
  },
  {
    path: '/appsettings',
    name: 'appsettings',
    component: AppSettings,
    meta: {
      requiresAuth: true,
      is_admin: true,
    },
  },
  {
    path: '/cases',
    name: 'cases',
    component: Cases,
    meta: {
      requiresAuth: true,
    },
  },
];

export function createRouter() {
  const router = new VueRouter({
    routes,
    mode: 'history',
    saveScrollPosition: true,
    linkExactActiveClass: 'exact-active',
    linkActiveClass: 'active',
    scrollBehavior() {
      return { x: 0, y: 0 };
    },
  });

  router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (localStorage.getItem('jwt') == null) {
        next({
          path: '/',
          params: { nextUrl: to.fullPath },
        });
      } else {
        const user = JSON.parse(localStorage.getItem('user'));
        if (to.matched.some((record) => record.meta.is_admin)) {
          if (user === null) {
            next({
              path: '/',
              params: { nextUrl: to.fullPath },
            });
          } else if (user.role === 0) {
            next();
          } else {
            next({ name: 'depotpage' });
          }
        } else {
          next();
        }
      }
    } else {
      next();
    }
  });

  return router;
}

export default createRouter;
