import axios from 'axios';

export default {
  name: 'cases',
  data() {
    return {
      dialog: true,
      image: undefined,
      depotList: [
        'Gävle',
        'Göteborg',
        'Jönköping',
        'Karlstad',
        'Luleå',
        'Malmö',
        'Södertälje',
        'Visby',
      ],
      lister: [],
      displayHistory: [],
      displayObjects: {},
      updateDisplayObjects: {},
    };
  },
  mounted() {
    this.image = `${process.env.VUE_APP_BACKENDURL}/api/upload`;
    // this.initiateMails();
  },
  watch: {
    updateDisplayObjects() {
      this.displayObjects = this.updateDisplayObjects;
    },
  },
  methods: {
    test() {
      this.displayObjects = {};
      this.dialog = false;
      for (const key of this.lister) {
        this.displayObjects[key] = [];
        this.updateDisplayObjects[key] = [];
      }
      for (const depotCase of this.displayHistory) {
        if (depotCase.depot in this.displayObjects) {
          this.displayObjects[depotCase.depot].push(depotCase);
        }
      }
      // setTimeout(this.intervaler, 30000);
      this.intervaler();
    },
    async intervaler() {
      let formatTime;
      const history = [];
      this.displayHistory = [];
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/mail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        // Loop för att hitta mallar som ska in i mallListan
        for (const responseCase of response.data) {
          if (responseCase.active === true) {
            responseCase.finish = false;
          }
          history.push(responseCase);
        }
        console.log(history);
        for (const responseCase of history) {
          formatTime = this.format_date(responseCase.activeMail.date);
          responseCase.activeMail.date = formatTime;
          responseCase.dateStart = this.format_startDate(responseCase.dateStart);
          responseCase.dateEnd = this.format_endDate(responseCase.dateEnd);
          // responseCase.dateStart = responseCase.dateStart.toLocaleString('en-GB', {

          // Loop through all mails and change their dates accordingly.
          if (responseCase.mails.length > 0) {
            for (const mail of responseCase.mails) {
              formatTime = this.format_date(mail.date);
              mail.date = formatTime;
            }
          }
        }
        this.displayHistory = history.filter((historyObj) => historyObj.active === true);
        console.log(this.displayHistory);
        this.displayObjects = {};
        this.dialog = false;
        for (const key of this.lister) {
          this.displayObjects[key] = [];
          this.updateDisplayObjects[key] = [];
        }
        for (const depotCase of this.displayHistory) {
          if (depotCase.depot in this.displayObjects) {
            this.displayObjects[depotCase.depot].push(depotCase);
          }
        }
        setTimeout(this.intervaler, 30000);
      });
    },
    format_date(dateInput) {
      const newDate = new Date(dateInput);
      newDate.setHours(newDate.getHours());
      var isoDateTime = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString(); // eslint-disable-line
      const dateYear = isoDateTime;
      const date = dateYear.split('T')[0];
      const time = dateYear.split('T')[1].split('.')[0];
      const formatTime = `${date} ${time}`;
      return formatTime.slice(0, -3);
    },
    format_startDate(dateInput) {
      if (dateInput === undefined) {
        return 'Nu';
      }
      const newDate = new Date(dateInput);
      var isoDateTime = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString(); // eslint-disable-line
      const dateYear = isoDateTime;
      const date = dateYear.split('T')[0];
      const time = dateYear.split('T')[1].split('.')[0];
      const formatTime = `${date} ${time}`;
      return formatTime.slice(0, -3);
    },
    format_endDate(dateInput) {
      if (dateInput === undefined) {
        return 'Tillsvidare';
      }
      const newDate = new Date(dateInput);
      var isoDateTime = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString(); // eslint-disable-line
      const dateYear = isoDateTime;
      const date = dateYear.split('T')[0];
      const time = dateYear.split('T')[1].split('.')[0];
      const formatTime = `${date} ${time}`;
      return formatTime.slice(0, -3);
    },
  },
};
