/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import axios from 'axios';
import * as CodeMirror from 'codemirror';
import 'codemirror/addon/hint/show-hint.css';
// import 'codemirror/addon/hint/anyword-hint';
import 'codemirror/addon/hint/show-hint';
import 'codemirror/lib/codemirror.css'; // the theme style is introduced here. It must be introduced according to the theme set!!
import 'vue-datetime/dist/vue-datetime.css';
import { Datetime } from 'vue-datetime';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: 'depotpage',
  data() {
    return {
      editedPerson: {},
      rulesDepot: {
        select: [(v) => !!v || 'Item is required'],
      },
      dialogDos: false,
      dialogDres: false,
      dialogVier: false,
      singlePersonDepotDict: {},
      singlePersonDepotList: [],
      selectedFooter: [],
      depotFooters: [
        {
          depot: 'Gävle',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Göteborg',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Jönköping',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Karlstad',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Luleå',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Malmö',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Södertälje',
          footerText: 'asdasd asdasd',
        },
        {
          depot: 'Visby',
          footerText: 'asdasd asdasd',
        },
      ],
      currentPerson: 0,
      currentPersonIndex: 0,
      toggleSelect: false,
      dialogFinishCase: false,
      updateFooterInformation: false,
      itemFinish: '',
      loaderDialog: false,
      activeHistoryNumber: '',
      alert: false,
      alertApprovedApi: false,
      alertSuccess: false,
      activeCase: false,
      errorMessage: '',
      role: '',
      rules: {
        name: [(val) => (val || '').length > 0 || 'This field is required'],
      },
      dateStart: '',
      dateStartFront: '',
      dateEnd: '',
      dateEndFront: '',
      dateFormattedStart: '',
      dateFormattedEnd: '',
      templateClear: false,
      selectedDepot: null,
      productCounter: 0,
      caseTitle: 'Depå',
      caseTitleDepot: '<Depåort>',
      caseTitleText: '<Ärendemall>',
      caseTitleTemp: 'Depå',
      depotList: [
        'Gävle',
        'Göteborg',
        'Jönköping',
        'Karlstad',
        'Luleå',
        'Malmö',
        'Södertälje',
        'Visby',
      ],
      snippets: [
        { text: '', textId: 'depot' },
        { text: '', textId: 'timeStart' },
        { text: '', textId: 'timeEnd' },
        // other snippets for hinting
      ],
      cmOptions: {
        mode: 'string',
        lineNumbers: false,
        line: true,
      },
      currentHistory: false,
      code: '',
      manipulatedCode: '',
      addDialog: false,
      showMenu: false,
      x: 0,
      y: 0,
      showGroupButton: true,
      registeredGroup: {
        groupId: null,
        groupName: '',
        persons: [],
      },
      messageContent: '',
      groupsUtskick: [],
      chosenTemplate: null,
      chosenPerson: null,
      mailToGroup: '',
      model: null,
      selectedProduct: '',
      activeHistory: null,
      colors: [
        'green',
        'secondary',
        'yellow darken-4',
        'red lighten-2',
        'orange darken-1',
      ],
      dialog: false,
      dialog2: false,
      dialogDelete: false,
      dialogDeleteGroup: false,
      dialogUpdateGroup: false,
      dialogPreview: false,
      displayDeleteGroupButton: false,
      displayUpdateGroupButton: false,
      dialogHistory: false,
      dialogFullHistory: false,
      dialogGroup: false,
      dialogSingleGroup: false,
      displayHistory: [],
      image: undefined,
      icon: undefined,
      productInput: '',
      rubrik: '',
      inputUpload: undefined,
      sendCode: '',
      items: [
        {
          title: '1',
        },
        {
          title: '2',
        },
        {
          title: '3',
        },
        {
          title: '4',
        },
      ],
      tempName: '',
      editedItem: {
        name: '',
      },
      historyCarouselList: {
        histList: [],
      },
      headers: [
        {
          text: 'Produkt',
          align: 'start',
          sortable: false,
          value: 'name',
          width: '1%',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      headersPerons: [
        {
          text: 'Förnamn',
          value: 'name',
          sortable: true,
          width: '15px',
        },
        {
          text: 'Efternamn',
          value: 'lastName',
          sortable: true,
          width: '15px',
        },
        {
          text: 'Företagsinfo',
          value: 'companyTextField',
          sortable: false,
          width: '15px',
        },
        {
          text: 'Mail',
          value: 'mail',
          sortable: false,
          width: '15px',
        },
        {
          text: 'Telefonnummer',
          value: 'phoneNumber',
          sortable: false,
          width: '15px',
        },
        {
          text: 'Utskickslås',
          value: 'isLocked',
          sortable: true,
          width: '5px',
        },
        {
          text: 'Hantering',
          value: 'actions',
          sortable: false,
          width: '15px',
        },
      ],
      headersHistory: [
        {
          text: 'Avsluta',
          value: 'finish',
          sortable: false,
          width: '15px',
        },
        {
          text: 'Ärendenummer',
          value: 'serialNumber',
          sortable: false,
        },
        {
          text: 'Utskicksdatum',
          align: 'left',
          value: 'activeMail.date',
          width: '165px',
        },
        {
          text: 'Grupper',
          value: 'caseGroups',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Meddelande',
          value: 'activeMail.content',
          sortable: false,
          width: '50px',
        },
        {
          text: 'Mer info',
          value: 'actions',
          sortable: false,
        },
      ],
      historyAllList: [],
      headersAllHistory: [
        {
          text: 'Ärendenummer',
          value: 'serialNumber',
          sortable: false,
          width: '100px',
        },
        {
          text: 'Aktiv',
          value: 'active',
          width: '10px',
        },
        {
          text: 'Ärende',
          value: 'subject',
          width: '80px',
        },
        {
          text: 'Utskicksdatum',
          align: 'left',
          value: 'date',
          width: '165px',
        },
        {
          text: 'Depå',
          value: 'depot',
          sortable: false,
          width: '90px',
        },
        {
          text: 'Produkter',
          value: 'products',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Grupper',
          value: 'caseGroups',
          sortable: false,
          width: '200px',
        },
        {
          text: 'Meddelande',
          value: 'content',
          sortable: false,
          width: '100px',
        },
      ],
      products: [
      ],
      groups: [
      ],
      templateList: [
      ],
      templateActive: false,
      chosenGroup: '',
      chosenCase: {},
      currentGroup: [
        {
          groupName: 'Välj grupp för information om grupp',
        },
      ],
      checkbox: false,
      content: {
        LogoutButton: null,
        Loading_text: null,
        Maintitle: null,
        Messagebox_text: null,
        Title_left_1: null,
        List_left_1: null,
        List_left_2: null,
        List_left_3: null,
        List_left_4: null,
        Input_left_1: null,
        Input_left_2: null,
        Button_left_1: null,
        Button_left_2: null,
        Button_left_2_disabled: null,
        Button_left_2_update: null,
        Button_left_3: null,
        Checkbox_left_1: null,
        Title_right_1: null,
        Title_right_2: null,
        Title_right_3: null,
        Text_right_1: null,
        List_right_1: null,
        Input_right_1: null,
        Button_right_1: null,
        Button_right_2: null,
        Button_right_3: null,
        Button_right_4: null,
        Button_right_5: null,
        Produkt_redigera_title: null,
        Produkt_redigera_text_1: null,
        Produkt_redigera_button_1: null,
        Produkt_redigera_button_2: null,
        Produkt_tabort_title: null,
        Produkt_tabort_button_1: null,
        Produkt_tabort_button_2: null,
        Grupp_list_text_1: null,
        Currenthistorik_title: null,
        Allhistorik_title: null,
        Allhistorik_button_1: null,
        Historikinfo_title: null,
        Historikinfo_button_1: null,
        Utskicksmall_title: null,
        Utskicksmall_title_update: null,
        Utskicksmall_text_1: null,
        Utskicksmall_button_1: null,
        Utskicksmall_button_2: null,
        Utskicksmall_button_3: null,
        Grupp_addPerson_title: null,
        Grupp_addPerson_text_1: null,
        Grupp_addPerson_text_2: null,
        Grupp_addPerson_text_3: null,
        Grupp_addPerson_text_4: null,
        Grupp_addPerson_text_5: null,
        Grupp_addPerson_button_1: null,
        Grupp_addPerson_button_2: null,
        Grupp_addPerson_button_3: null,
      },
      history: [],
      newGroupName: '',
      editedIndex: -1,
    };
  },
  watch: {
    templateClear(val) {
      console.log('asd');
      this.selectedDepot = '';
    },
    selectedDepot(val) {
      this.caseTitleDepot = '<Depåort>';
      if (val !== null) {
        this.caseTitleDepot = val;
      }
      console.log(val);
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    dialogHistory(val) {
      val || this.closeHistory();
    },
    dialogFullHistory(val) {
      val || this.closeFullHistory();
    },
    alert(newVal) {
      if (newVal) {
        setTimeout(() => { this.alert = false; }, 3000);
      }
    },
  },
  methods: {
    downloadPdf() {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/upload/pdf`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        responseType: 'blob',
      }).then((footerResponse) => {
        const linkSource = footerResponse.data;
        const downloadLink = document.createElement('a');
        const url = window.URL.createObjectURL(linkSource);
        const fileName = 'manual.pdf';
        downloadLink.href = url;
        downloadLink.target = '_blank';
        downloadLink.download = fileName;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        window.URL.revokeObjectURL(url);
      });
    },
    sendFooterInformation() {
      const token = localStorage.getItem('jwt');
      console.log(this.selectedFooter);
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/content/depotFooter`,
        {
          depot: this.selectedFooter.Depot,
          footerText: this.selectedFooter.FooterText,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content/depotFooter`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((footerResponse) => {
          this.depotFooters = footerResponse.data;
          console.log(footerResponse.data);
        });
      });
    },
    endCase(caseToEnd) {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/template/finish/${caseToEnd.caseId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.initiateMails();
        console.log(response);
        this.dialogFinishCase = false;
      });
    },
    showRemovePersonButton(i) {
      if (this.currentGroup[0].groupName !== 'Anmälda') {
        return (i || (!i && this.currentGroup[0].persons.length > 1)) && i < this.currentGroup[0].persons.length - 1; // eslint-disable-line max-len
      }
      return true;
    },
    asd() {
      if (this.productCounter === 0) {
        this.productCounter += 1;
        for (const product of this.selectedProduct) {
          this.snippets.push({ text: product, textId: 'productList' });
        }
      } else if (this.selectedProduct.length > this.productCounter) {
        this.snippets.push({ text: this.selectedProduct[this.selectedProduct.length - 1], textId: 'productList' });
        this.productCounter += 1;
      } else if (this.selectedProduct.length < this.productCounter) {
        if (this.snippets[this.snippets.length - 1].textId === 'productList') {
          this.snippets.pop();
          this.productCounter -= 1;
        }
      }
    },
    clearEndDate() {
      this.dateEnd = '';
      this.dateFormattedEnd = '';
    },
    clearStartDate() {
      this.dateFormattedStart = '';
      this.dateStart = '';
    },
    updateStartDate() {
      this.dateStart = new Date(this.dateStartFront);
      const tzoffset = (new Date()).getTimezoneOffset() * 60000;
      const localISOTime = (new Date(this.dateStart - tzoffset)).toISOString();
      // console.log(localISOTime);
      const dateFormated = this.format_date(localISOTime);
      this.dateFormattedStart = dateFormated.split(':').slice(0, -1).join(':');
      console.log(this.dateFormattedStart);
      this.chosenCase.dateStart = this.dateStart;
      const objIndex = this.snippets.findIndex((obj) => obj.textId === 'timeStart');
      const updatedObj = { ...this.snippets[objIndex], text: dateFormated };

      const updatedProjects = [
        ...this.snippets.slice(0, objIndex),
        updatedObj,
        ...this.snippets.slice(objIndex + 1),
      ];
      this.snippets = updatedProjects;
    },
    updateEndDate() {
      this.chosenCase.dateEnd = {};
      this.dateEnd = new Date(this.dateEndFront);
      const tzoffset = (new Date()).getTimezoneOffset() * 60000; // offset in milliseconds
      const localISOTime = (new Date(this.dateEnd - tzoffset)).toISOString();
      console.log(localISOTime);
      const dateFormated = this.format_date(localISOTime);
      this.dateFormattedEnd = dateFormated.split(':').slice(0, -1).join(':');
      console.log(this.dateFormattedEnd);
      console.log(this.dateEnd);
      this.chosenCase.dateEnd = this.dateEnd;
      const objIndex = this.snippets.findIndex((obj) => obj.textId === 'timeEnd');
      const updatedObj = { ...this.snippets[objIndex], text: dateFormated };

      const updatedProjects = [
        ...this.snippets.slice(0, objIndex),
        updatedObj,
        ...this.snippets.slice(objIndex + 1),
      ];
      this.snippets = updatedProjects;
    },
    updateProduct() {
      const objIndex = this.snippets.findIndex((obj) => obj.textId === 'product');
      const updatedObj = { ...this.snippets[objIndex], text: this.selectedProduct };

      const updatedProjects = [
        ...this.snippets.slice(0, objIndex),
        updatedObj,
        ...this.snippets.slice(objIndex + 1),
      ];
      this.snippets = updatedProjects;
    },
    closeDepotList() {
      this.toggleSelect = false;
      this.singlePersonDepotList = [];
    },
    setPersonDepotData(index) {
      this.toggleSelect = !this.toggleSelect;
      this.currentPerson = this.currentGroup[0].persons[index].personId;
      this.currentPersonIndex = index;
      this.singlePersonDepotDict = this.currentGroup[0].persons[index].depots;
      console.log(this.currentGroup[0].persons[index].depots);
      for (const item in this.singlePersonDepotDict) { // eslint-disable-line
        if (this.singlePersonDepotDict[item].depotChosen) {
          this.singlePersonDepotList.push(this.singlePersonDepotDict[item].depotName);
        }
      }
    },
    updateDepotList() {
      const token = localStorage.getItem('jwt');
      const depotsToSend = [];
      for (const item of this.singlePersonDepotList) { // eslint-disable-line
        depotsToSend.push({
          depotName: item,
          depotChosen: true,
        });
      }
      const depotListCopy = [...this.singlePersonDepotList];
      // Reset the list.
      const newDepotList = this.currentGroup[0].persons[this.currentPersonIndex].depots.map((obj) => { //eslint-disable-line
        return { ...obj, depotChosen: false };
      });
      for (const item in this.currentGroup[0].persons[this.currentPersonIndex].depots) { //eslint-disable-line
        if (depotListCopy.includes(this.currentGroup[0].persons[this.currentPersonIndex].depots[item].depotName)) { //eslint-disable-line
          newDepotList[item].depotChosen = true;
        }
        console.log(depotListCopy.includes(this.currentGroup[0].persons[this.currentPersonIndex].depots[item].depotName)); //eslint-disable-line
      }
      this.currentGroup[0].persons[this.currentPersonIndex].depots = newDepotList;
      this.currentGroup[0].persons[this.currentPersonIndex].depotSendListCount = newDepotList.filter((obj) => obj.depotChosen === true).length; //eslint-disable-line
      console.log(newDepotList);
      console.log(depotsToSend);
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/group/updateSingleDepot`,
        {
          personId: this.currentPerson,
          depots: depotsToSend,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
      });
    },
    updateSinglePersonClose() {
      this.dialogDres = false;
      this.$nextTick(() => {
        this.editedPerson = {};
        this.editedIndex = -1;
      });
    },
    updateSinglePersonInit(item) {
      this.editedIndex = this.currentGroup[0].persons.indexOf(item);
      this.editedPerson = ({ ...item });
      this.dialogDres = true;
      console.log(this.editedPerson);
    },
    updateSinglePerson() {
      console.log(this.editedPerson);
      const token = localStorage.getItem('jwt');
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/group/updatePersonExternal`,
        {
          company: this.editedPerson.companyTextField,
          identifier: this.editedPerson.userId,
          email: this.editedPerson.mail,
          phone: this.editedPerson.phoneNumber,
          updateId: this.editedPerson.groupId,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
      });
    },
    updateSinglePersonLock(person) {
      let { userId } = person;
      const { mail } = person;
      const { phoneNumber } = person;
      const { groupId } = person;
      const { companyTextField } = person;
      const { isLocked } = person;
      if (isLocked) {
        person.isLocked = false; //eslint-disable-line
      } else { person.isLocked = true; } //eslint-disable-line
      if (userId === undefined) {
        const { personId } = person;
        userId = String(personId);
      }
      const token = localStorage.getItem('jwt');
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/group/updatePersonLock`,
        {
          company: companyTextField,
          identifier: userId,
          email: mail,
          phone: phoneNumber,
          updateId: groupId,
          lockUpdate: person.isLocked,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
      });
    },
    updateTemplate() {
      console.log(this.tempName, this.chosenTemplate.templateTitle);
      console.log(this.chosenTemplate.templateContent);
      const token = localStorage.getItem('jwt');
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/template/${this.chosenTemplate.templateId}`,
        {
          templateName: this.tempName,
          templateTitle: this.caseTitle,
          templateContent: this.code,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
        this.initiateTemplateList();
      });
    },
    deleteTemplate() {
      const token = localStorage.getItem('jwt');
      axios.delete(`${process.env.VUE_APP_BACKENDURL}/api/template/${this.chosenTemplate.templateId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.tempName = '';
        this.templateActive = false;
        this.chosenTemplate = '';
        this.initiateTemplateList();
        console.log(response);
      });
    },
    addGroup() {
      const token = localStorage.getItem('jwt');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/group`,
        {
          GroupName: this.newGroupName,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
        this.initiateGroups();
      });
      this.dialogGroup = false;
    },
    addRegisteredPerson() {
      console.log(this.currentGroup[0]);
      console.log(this.chosenPerson);
      console.log('e');
      console.log(this.registeredGroup.persons[0]);
      this.currentGroup[0].persons.push(this.chosenPerson);
      this.dialog2 = false;
      this.registeredGroup.persons = this.registeredGroup.persons.filter(
        (item) => item.personId !== this.chosenPerson.personId,
      );
      console.log(this.registeredGroup.persons);
    },
    addSinglePersonInit() {
      this.dialogVier = true;
    },
    addSinglePersonClose() {
      this.dialogVier = false;
      this.$nextTick(() => {
        this.editedPerson = {};
        this.editedIndex = -1;
      });
    },
    addSinglePerson() {
      const addPerson = {
        name: this.editedPerson.name,
        lastname: this.editedPerson.lastName,
        company: this.editedPerson.companyTextField,
        mail: this.editedPerson.mail,
        active: false,
        phonenumber: this.editedPerson.phoneNumber,
      };
      const token = localStorage.getItem('jwt');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/group/addPerson`,
        {
          id: this.currentGroup[0].groupId,
          person: addPerson,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
        axios.get(`${process.env.VUE_APP_BACKENDURL}/api/group`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((groupResponse) => {
            const group = groupResponse.data.find((o) => o.groupId === this.currentGroup[0].groupId); // eslint-disable-line
            this.currentGroup[0].persons = group.persons;
            this.currentGroup[0].persons.push({ name: '', mail: '', controlIcon: 'mdi-help' });
          });
      });
    },
    addSinglePersonEntry() {
      for (const person of this.currentGroup[0].persons) { // eslint-disable-line
        if (this.currentGroup[0].groupType !== 'depot') {
          if (this.currentGroup[0].persons[this.currentGroup[0].persons.length - 1].name !== '') {
            person.depotSendListCount = person.depots.filter((obj) => obj.depotChosen === true).length; // eslint-disable-line
          }
        }
      }
      if (this.currentGroup[0].groupType !== 'depot' && this.currentGroup[0].groupName !== 'Anmälda') {
        if (this.currentGroup[0].persons[this.currentGroup[0].persons.length - 1].name !== '') {
          this.currentGroup[0].persons.push({ name: '', mail: '', controlIcon: 'mdi-help' });
        }
      }
      this.dialogDos = true;
      console.log(this.currentGroup[0].persons);
      // this.dialogSingleGroup = true;
    },
    addTemplate() {
      const date = new Date(Date.now());
      const dateYear = date.toISOString();
      // dateYear = dateYear.split('T')[0];
      // date = date.replace('Z', '').replace('T', ' ')
      let e = date.getHours();
      const f = date.getMinutes();
      if (e < 10) {
        e = `0${e}`;
      }
      const token = localStorage.getItem('jwt');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/template`, {
        TemplateName: this.tempName,
        TemplateTitle: this.caseTitle,
        TemplateContent: this.code,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        console.log(response);
        this.initiateTemplateList();
      });
      // this.history.unshift(ey)
    },
    changeTemplateContent() {
      console.log(this.activeCase);
      console.log(this.templateActive);
      console.log(this.chosenTemplate);
      if (this.templateClear !== true) {
        this.templateActive = true;
        this.tempName = this.chosenTemplate.templateName;
        this.caseTitle = this.chosenTemplate.templateName;
        this.caseTitleDepot = this.chosenTemplate.templateName.split(' ')[1];
        this.caseTitleText = this.chosenTemplate.templateName.split(' ').slice(2).join(' ');
        this.caseTitleTemp = this.chosenTemplate.templateName.split(' ')[0];
        this.selectedDepot = this.chosenTemplate.templateName.split(' ')[1];
        this.code = this.chosenTemplate.templateContent;
      } else {
        this.templateActive = false;
        this.templateClear = false;
        this.tempName = '';
        this.caseTitleText = '<Ärendemall>';
        this.caseTitleDepot = '<Depåort>';
        this.caseTitleTemp = 'Depå';
        this.selectedDepot = null;
        this.code = '';
        this.dateStart = '';
        this.dateEnd = '';
      }
    },
    changeDisplayContent() {
      function checkAndConvertHours(hours) {
        let retHours = hours === 23 ? 0 : hours;
        retHours += 1;
        let retString = '0';
        if (retHours > 9) retString = '';
        return retString + retHours.toString();
      }
      function replaceDatesInText(code, products, depotList) {
        const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2} [0-9]{2}:[0-9]{2}/;
        let retText = code.replace(dateRegex, '#Från');
        retText = retText.replace(dateRegex, '#Till');
        retText = retText.replace('Nu', '#Från');
        retText = retText.replace('Tillsvidare', '#Till');
        console.log(retText);
        products = products.map(i => i + ', '); // eslint-disable-line
        products[products.length - 1] = products[products.length - 1].replace(', ', ''); // eslint-disable-line
        const productsText = products.join('');
        retText = retText.replace(productsText, '#Produkter');
        const depotRegex = new RegExp('(' + depotList.join('|') + ')'); // eslint-disable-line
        retText = retText.replace(depotRegex, '#Depå');
        const FooterObj = this.depotFooters.find((obj) => obj.Depot === this.selectedDepot);
        const footerText = FooterObj.FooterText;
        retText = retText.replace(footerText, '#Footer');
        this.selectedDepot = '';
        return retText;
      }
      if (this.chosenCase.templateType === 'reset') {
        this.chosenCase = {};
        this.selectedProduct = '';
        this.caseTitle = 'Depåinformation St1';
        this.dateStart = '';
        this.dateStartFront = '';
        this.dateFormattedStart = '';
        this.dateEndFront = '';
        this.dateFormattedEnd = '';
        this.dateEnd = '';
        this.selectedProduct = '';
        this.selectedDepot = '';
        this.mailToGroup = '';
        this.code = '';
        this.activeCase = false;
      } else {
        console.log(new Date(this.chosenCase.dateStart), new Date(this.chosenCase.dateEnd));
        console.log(this.chosenCase.activeMail);
        this.activeCase = true;
        this.selectedProduct = this.chosenCase.activeMail.products.map((obj) => obj.name);
        this.mailToGroup = this.chosenCase.activeMail.groups.map((obj) => obj.groupName);
        this.selectedDepot = this.chosenCase.depot;
        this.code = this.chosenCase.activeMail.content;
        this.caseTitle = this.chosenCase.subject;
        let dateHours;
        // START date
        if (this.chosenCase.dateStart !== undefined) {
          console.log('im here..');
          console.log(this.chosenCase.dateStart);
          this.dateStart = new Date(new Date(this.chosenCase.dateStart).setHours(new Date(this.chosenCase.dateStart).getHours() + 1)); // eslint-disable-line
          this.dateStartFront = this.dateStart.toISOString();
          dateHours = parseInt(this.dateStartFront.replace('T', ':').split(':')[1], 10);
          console.log(dateHours);
          this.dateStartFront = this.dateStartFront.replace('Z', '').split('T')[0];
          this.dateStartFront = `${this.dateStartFront} ${checkAndConvertHours(dateHours)}:00`;
          this.dateFormattedStart = this.chosenCase.dateStart.replace('T', ' ');
        }
        // END date
        console.log(this.chosenCase.dateEnd);
        if (this.chosenCase.dateEnd !== undefined) {
          this.dateEnd = new Date(new Date(this.chosenCase.dateEnd).setHours(new Date(this.chosenCase.dateEnd).getHours() + 1)); // eslint-disable-line
          this.dateEndFront = this.dateEnd.toISOString();
          dateHours = parseInt(this.dateEndFront.replace('T', ':').split(':')[1], 10);
          this.dateEndFront = this.dateEndFront.replace('Z', '').split('T')[0];
          this.dateEndFront = `${this.dateEndFront} ${checkAndConvertHours(dateHours)}:00`;

          this.dateFormattedEnd = this.chosenCase.dateEnd.replace('T', ' ');
          console.log(this.dateFormattedEnd, this.chosenCase.dateEnd);
        }
        // TEXT
        const textToChange = this.code;
        const productsToChange = this.selectedProduct;
        console.log(this.depotList);
        this.code = replaceDatesInText(textToChange, productsToChange, this.depotList);
      }
    },
    changeGroupDisplay() {
      if (this.chosenGroup === 'Alla') {
        this.showGroupButton = false;
        this.currentGroup = [];
        this.currentGroup = this.groups;
        this.currentGroup = this.groups.slice(1);
      } else {
        this.showGroupButton = true;
        for (const group of this.groups) {
          if (group.groupName === this.chosenGroup) {
            this.currentGroup = [];
            this.currentGroup.push(group);
          }
        }
      }
      if (this.chosenGroup === 'Anmälda') {
        // this.showGroupButton = false;
      }
      // this.currentGroup.pop()
      if (this.currentGroup[0].groupType === 'created' && (this.chosenGroup !== 'Alla' && this.chosenGroup !== 'Anmälda')) {
        this.displayDeleteGroupButton = true;
        this.displayUpdateGroupButton = true;
      } else if (this.currentGroup[0].groupType === 'depot' && (this.chosenGroup !== 'Alla' && this.chosenGroup !== 'Anmälda')) {
        this.displayUpdateGroupButton = true;
        this.displayDeleteGroupButton = false;
      } else {
        this.displayDeleteGroupButton = false;
        this.displayUpdateGroupButton = false;
      }
    },
    async checkDrivers(groups) {
      const tempGroups = groups;
      const groupsPostStructure = [];
      // console.log(tempGroups);
      // Set to loading state
      for (const group in tempGroups.slice(0, tempGroups.length - 1)) {
        if (tempGroups.hasOwnProperty(group)) { // eslint-disable-line no-prototype-builtins
          tempGroups[group].loading = true;
          const groupPostObj = {};
          groupPostObj.Name = tempGroups[group].name;
          groupPostObj.LastName = tempGroups[group].lastName;
          groupPostObj.companyTextField = tempGroups[group].companyTextField;
          groupPostObj.mail = tempGroups[group].mail;
          groupsPostStructure.push(groupPostObj);
        }
      }
      console.log(groupsPostStructure);
      let courses = [];
      let courseCount = 0;
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/status/courses`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        const coursesResponse = response.data;
        for (const courseData in coursesResponse) {
          if (coursesResponse[courseData].active) {
            courses.push(coursesResponse[courseData].name);
            courseCount += 1;
          }
        }
        if (courseCount === coursesResponse.length) {
          courses = ['*'];
          console.log(courses);
        }
        axios.post(`${process.env.VUE_APP_BACKENDURL}/status`,
          {
            StatusList: groupsPostStructure,
            Courses: courses,
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((postResponse) => {
          for (const group in tempGroups) {
            if (tempGroups.hasOwnProperty(group)) { // eslint-disable-line no-prototype-builtins
              if (postResponse.data.statusList[group]) {
                tempGroups[group].loading = false;
                tempGroups[group].controlIcon = 'mdi-check-bold';
                tempGroups[group].iconColor = 'green';
              } else {
                tempGroups[group].loading = false;
                tempGroups[group].controlIcon = 'mdi-close-thick';
                tempGroups[group].iconColor = 'red';
              }
            }
          }
        })
          .catch(() => {
            console.log('FAILURE!!');
          });
      });
      console.log(groupsPostStructure);
    },
    sleep(time) {
      return new Promise((resolve) => setTimeout(resolve, time));
    },
    addProduct() {
      const token = localStorage.getItem('jwt');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/product/`,
        {
          name: this.productInput,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        this.initateProducts();
        console.log(response);
      });
    },
    editItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = { ...item };
      this.dialog = true;
    },
    showCurrentHistory() {
      this.currentHistory = true;
      this.dialogFullHistory = true;
      let historyMail;
      console.log(this.history);
      for (const mail of this.history) {
        if (mail.active) {
          historyMail = mail.activeMail;
          historyMail.caseType = mail.templateType;
          historyMail.active = 'Ja';
          historyMail.products = mail.products;
          historyMail.depot = mail.depot;
          historyMail.caseGroups = mail.caseGroups;
          historyMail.subject = mail.subject;
          historyMail.serialNumber = mail.serialNumber;
          this.historyAllList.push(historyMail);
        }
      }
      console.log(this.historyAllList);
    },
    showHistory(item) {
      const copiedMail = JSON.parse(JSON.stringify(item.mails));
      this.historyCarouselList = null;
      this.historyCarouselList = copiedMail;
      this.historyCarouselList.unshift(item.activeMail);
      this.activeHistoryNumber = item.serialNumber;
      this.dialogHistory = true;
    },
    showFullHistory() {
      let historyMail;
      this.currentHistory = false;
      this.dialogFullHistory = true;
      console.log(this.history);
      for (const mail of this.history) {
        if (!(mail.active)) {
          historyMail = mail.activeMail;
          historyMail.caseType = mail.templateType;
          historyMail.active = 'Nej';
          historyMail.depot = mail.depot;
          historyMail.subject = mail.subject;
          historyMail.caseGroups = mail.caseGroups;
          historyMail.serialNumber = mail.serialNumber;
          this.historyAllList.push(historyMail);
          console.log(historyMail);
          if (mail.mails.length !== 0) {
            for (const oldMail of mail.mails) {
              oldMail.subject = '-Äldre- ' + mail.subject; // eslint-disable-line prefer-template
              oldMail.caseType = mail.templateType;
              oldMail.active = 'Nej';
              oldMail.caseGroups = mail.caseGroups;
              oldMail.serialNumber = mail.serialNumber;
              this.historyAllList.push(oldMail);
            }
          }
        } else {
          historyMail = mail.activeMail;
          historyMail.caseType = mail.templateType;
          historyMail.active = 'Ja';
          historyMail.depot = mail.depot;
          historyMail.subject = mail.subject;
          historyMail.caseGroups = mail.caseGroups;
          historyMail.serialNumber = mail.serialNumber;
          this.historyAllList.push(historyMail);
          if (mail.mails.length !== 0) {
            for (const oldMail of mail.mails) {
              oldMail.subject = '-Äldre- ' + mail.subject; // eslint-disable-line prefer-template
              oldMail.caseType = mail.templateType;
              oldMail.active = 'Ja';
              oldMail.products = mail.products;
              oldMail.caseGroups = mail.caseGroups;
              oldMail.serialNumber = mail.serialNumber;
              this.historyAllList.push(oldMail);
            }
          }
        }
      }
      console.log(this.historyAllList);
    },
    setActiveHistory(item) {
      this.activeHistory = item;
    },
    initiateBackground() {
      console.log(this.content);
      this.image = `${process.env.VUE_APP_BACKENDURL}/api/upload`;
    },
    initiateSendCode() {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content/registercode`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.sendCode = response.data.code;
      });
    },
    initiateIcon() {
      this.icon = `${process.env.VUE_APP_BACKENDURL}/api/uploadIcon`;
    },
    initiateMails() {
      let formatTime;
      this.history = [];
      this.displayHistory = [];
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/mail`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        // Loop för att hitta mallar som ska in i mallListan
        for (const responseCase of response.data) {
          if (responseCase.active === true) {
            responseCase.finish = false;
          }
          this.history.push(responseCase);
        }
        for (const responseCase of this.history) {
          formatTime = this.format_date(responseCase.activeMail.date);
          responseCase.activeMail.date = formatTime;

          // Loop through all mails and change their dates accordingly.
          if (responseCase.mails.length > 0) {
            for (const mail of responseCase.mails) {
              formatTime = this.format_date(mail.date);
              mail.date = formatTime;
            }
          }
        }
        this.displayHistory = this.history.filter((historyObj) => historyObj.active === true);
        console.log(this.displayHistory);
        console.log(this.history);
      });
    },
    format_date(dateInput) {
      const dateYear = dateInput;
      const date = dateYear.split('T')[0];
      const time = dateYear.split('T')[1].split('.')[0];
      const formatTime = `${date} ${time}`;
      return formatTime;
    },
    logout() {
      console.log('clicked logout');
      localStorage.removeItem('user');
      localStorage.removeItem('jwt');
      this.$router.push('/');
    },
    deleteItem(item) {
      this.editedIndex = this.products.indexOf(item);
      this.editedItem = { ...item };
      this.dialogDelete = true;
    },
    deleteGroup() {
      if (this.chosenGroup !== '') {
        console.log(this.chosenGroup);
        const token = localStorage.getItem('jwt');
        axios.delete(`${process.env.VUE_APP_BACKENDURL}/api/group/${this.currentGroup[0].groupId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          console.log(response.data);
          this.dialogDeleteGroup = false;
          this.initiateGroups();
        });
      }
    },
    deleteItemConfirm(id) {
      const token = localStorage.getItem('jwt');
      axios.delete(`${process.env.VUE_APP_BACKENDURL}/api/product/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.initateProducts();
        console.log(this.products);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    closeHistory() {
      this.model = null;
      this.historyCarouselList = null;
      this.dialogHistory = false;
      this.activeHistory = null;
    },
    closeFullHistory() {
      this.dialogFullHistory = false;
      this.historyAllList = [];
    },
    save() {
      const token = localStorage.getItem('jwt');
      axios.put(`${process.env.VUE_APP_BACKENDURL}/api/product/${this.editedItem.productId}`, {
        name: this.editedItem.name,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.initateProducts();
        console.log(this.products);
      })
        .catch(() => {
          console.log('FAILURE!!');
        });
      this.close();
    },
    manipulateCode() {
      this.changeMailBodyContent();
      this.dialogPreview = true;
    },
    changeMailBodyContent() {
      this.manipulatedCode = '';
      const productString = this.selectedProduct.join(', ');
      let startDateTime = '';
      let endDateTime = '';
      console.log(this.dateStart);
      if (this.dateStart === '') {
        this.dateFormattedStart = 'Nu';
      } else {
        // date = this.dateStart.toISOString().replace('Z', '').replace('T', ' ').split(':')[0] +':'
        // time = this.dateStart.toISOString().replace('Z', '').replace('T', ' ').split(':')[1];
        console.log(this.dateFormattedStart);
        const newDate = new Date(this.dateFormattedStart);
        newDate.setHours(newDate.getHours() + 2);
        var isoDateTime = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString(); // eslint-disable-line
        const dateYear = isoDateTime; // eslint-disable-line
        const date = dateYear.split('T')[0];
        const time = dateYear.split('T')[1].split('.')[0];
        const formatTime = `${date} ${time}`;
        // startDateTime = formatTime.slice(0, -3);
        startDateTime = this.dateFormattedStart;
      }

      if (this.dateEnd === '') {
        this.dateFormattedEnd = 'Tillsvidare';
      } else {
        // date = this.dateEnd.toISOString().replace('Z', '').replace('T', ' ').split(':')[0] + ':'
        // time = this.dateEnd.toISOString().replace('Z', '').replace('T', ' ').split(':')[1];
        // endDateTime = date + time;
        const newDate = new Date(this.dateFormattedEnd);
        newDate.setHours(newDate.getHours() + 2);
        var isoDateTime = new Date(newDate.getTime() - (newDate.getTimezoneOffset() * 60000)).toISOString(); // eslint-disable-line
        const dateYear = isoDateTime; // eslint-disable-line
        const date = dateYear.split('T')[0];
        const time = dateYear.split('T')[1].split('.')[0];
        const formatTime = `${date} ${time}`;
        endDateTime = formatTime.slice(0, -3);
        // endDateTime = this.dateFormattedEnd;
        console.log(this.dateFormattedEnd);
      }

      const prodRegex = /#[Pp]rodukter/;
      const startRegex = /#[Ff]rån/;
      const endRegex = /#[Tt]ill/;
      const depotRegex = /#[Dd]epå/;
      const footerRegex = /#[Ff]ooter/;

      // Replace #produkt
      const FooterObj = this.depotFooters.find((obj) => obj.Depot === this.selectedDepot);
      const footerText = FooterObj.FooterText;

      this.manipulatedCode = this.code.replace(prodRegex, productString);
      this.manipulatedCode = this.manipulatedCode.replace(startRegex, this.dateFormattedStart); // eslint-disable-line
      this.manipulatedCode = this.manipulatedCode.replace(endRegex, this.dateFormattedEnd); // eslint-disable-line
      this.manipulatedCode = this.manipulatedCode.replace(depotRegex, this.selectedDepot);
      this.manipulatedCode = this.manipulatedCode.replace(footerRegex, footerText);

      console.log(this.manipulatedCode);
    },
    sendMail() {
      this.changeMailBodyContent();
      // console.log(this.inputUpload);
      // this.checkDrivers(groups);
      const date = new Date(Date.now());
      let dateYear = date.toISOString();
      dateYear = dateYear.split('T')[0];
      // date = date.replace('Z', '').replace('T', ' ')
      let e = date.getHours();
      let f = date.getMinutes();
      if (e < 10) {
        e = `0${e}`;
      }
      if (f < 10) {
        f = `0${f}`;
      }
      dateYear = `${dateYear} ${e}:${f}`;
      const mailList = [];
      const tempProductList = [];
      const mailGroupList = [];
      let tempMailObject = {};

      const groupsToMail = this.groupsUtskick.filter((group) => this.mailToGroup.includes(group.groupName)); // eslint-disable-line max-len
      console.log(this.mailToGroup);
      for (const index in groupsToMail) { // eslint-disable-line guard-for-in
        mailGroupList.push({ GroupName: groupsToMail[index].groupName });
        const mailToList = groupsToMail[index].persons;
        console.log(mailToList);
        for (const personIndex in mailToList) { // eslint-disable-line guard-for-in
          tempMailObject = {};
          if (mailToList[personIndex].personId === undefined) {
            tempMailObject.InformationPersonId = mailToList[personIndex].userId;
          } else {
            console.log(mailToList[personIndex].personId);
            tempMailObject.InformationPersonId = mailToList[personIndex].personId.toString();
          }
          tempMailObject.Mail = mailToList[personIndex].mail;
          tempMailObject.Phone = mailToList[personIndex].phoneNumber;
          mailList.push(tempMailObject);
        }
      }

      if (this.dateStart === '') {
        this.dateStart = null;
      }
      if (this.dateEnd === '') {
        this.dateEnd = null;
      }

      for (const index in this.selectedProduct) { // eslint-disable-line guard-for-in
        tempProductList.push({ Name: this.selectedProduct[index] });
      }
      console.log(mailList, mailGroupList, tempProductList, this.caseTitle, this.code);
      this.caseTitle = `${this.caseTitleTemp} ${this.caseTitleDepot} ${this.caseTitleText}`;
      const token = localStorage.getItem('jwt');
      // console.log(this.dateStart.toISOString(), this.dateEnd);
      if (this.activeCase) {
        console.log(this.dateStart, this.dateEnd);
        axios.post(`${process.env.VUE_APP_BACKENDURL}/mail`,
          {
            TemplateType: 'Info',
            CaseId: this.chosenCase.caseId,
            InformationList: mailList,
            Groups: mailGroupList,
            Products: tempProductList,
            Subject: this.caseTitle,
            Body: this.manipulatedCode,
            Depot: this.selectedDepot,
            StartDate: this.dateStart,
            EndDate: this.dateEnd,
            Attachments: [],
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((hue) => {
          this.alert = true;
          this.alertSuccess = true;
          console.log(mailList, mailGroupList, this.selectedProduct, this.caseTitle, this.code);
          console.log(this.selectedDepot, this.dateFormattedStart, this.dateFormattedEnd);
          this.initiateMails();
          this.dateStart = '';
          this.dateFormattedStart = '';
          this.dateEnd = '';
          this.dateFormattedEnd = '';
          console.log(hue);
        }).catch((err) => {
          this.alert = true;
          this.alertSuccess = false;
          console.log(err.response.data);
        });
      } else {
        console.log(this.selectedProduct);
        console.log(tempProductList);
        axios.post(`${process.env.VUE_APP_BACKENDURL}/mail`,
          {
            TemplateType: 'Info',
            InformationList: mailList,
            Groups: mailGroupList,
            Products: tempProductList,
            Subject: this.caseTitle,
            Body: this.manipulatedCode,
            Depot: this.selectedDepot,
            StartDate: this.dateStart,
            EndDate: this.dateEnd,
            Attachments: [],
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then((hue) => {
          this.alert = true;
          this.alertSuccess = true;
          console.log(mailList, mailGroupList, this.selectedProduct, this.caseTitle, this.code);
          console.log(this.selectedDepot, this.dateFormattedStart, this.dateFormattedEnd);
          this.initiateMails();
          console.log(hue);
          this.dateStart = '';
          this.dateFormattedStart = '';
          this.dateEnd = '';
          this.dateFormattedEnd = '';
        }).catch((err) => {
          this.alert = true;
          this.alertSuccess = false;
          console.log(err.response.data);
        });
      }
    },
    createMailHeaderObj(date) {
      let mailHead = {};
      if (date === null) {
        mailHead = {
          TemplateName: this.tempName,
          Mail: {
            date: '',
            product: this.selectedProduct,
            group: this.mailToGroup,
            content: this.messageContent,
          },
        };
      } else {
        mailHead = {
          templateName: this.tempName,
          Mail: {
            date,
            product: this.selectedProduct,
            group: this.mailToGroup,
            content: this.messageContent,
          },
        };
      }
      return mailHead;
    },
    updateGroup() {
      console.log(this.currentGroup[0]);
      const token = localStorage.getItem('jwt');
      console.log(this.newGroupName);
      if (this.currentGroup[0].groupType === 'created') {
        axios.put(`${process.env.VUE_APP_BACKENDURL}/api/group/${this.currentGroup[0].groupId}`, {
          name: this.newGroupName,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          this.initiateGroups();
          this.dialogUpdateGroup = false;
          console.log(this.groups);
        });
      } else {
        const groupUrl = this.currentGroup[0].groupNameIdentifier.replace(' ', '%20');
        axios.put(`${process.env.VUE_APP_BACKENDURL}/api/group/groupInformation/${groupUrl}`, {
          name: this.newGroupName,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          this.initiateGroups();
          this.dialogUpdateGroup = false;
          console.log(this.groups);
        });
      }
    },
    updateGroups(nameToFind) {
      let searchGroup = this.groups.find(({ groupName }) => groupName === nameToFind);
      console.log(searchGroup);
      searchGroup = this.currentGroup;
      console.log(this.groups);
    },
    updateSendCode() {
      const token = localStorage.getItem('jwt');
      axios.post(`${process.env.VUE_APP_BACKENDURL}/api/content/registercode`,
        {
          code: this.sendCode,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
        console.log(response);
      });
    },
    initiateDepotFooters() {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content/depotFooter`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.depotFooters = response.data;
        console.log(response.data);
      });
    },
    initiateContent() {
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/content`)
        .then((response) => {
          console.log(response.data);
          for (let i = 0; i < response.data.contents.length; i += 1) {
            if (this.content[response.data.contents[i].id] === null) {
              this.content[response.data.contents[i].id] = response.data.contents[i].text;
            }
          }
          this.currentGroup[0].groupName = this.content.Grupp_list_text_1;
          this.code = this.content.Messagebox_text;
        });
    },
    initiateGroups() {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/group`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          this.groups = response.data;
          for (const group in this.groups) { // eslint-disable-line guard-for-in
            if (this.groups.hasOwnProperty(group)) { // eslint-disable-line no-prototype-builtins
              if (this.groups[group].groupName === 'Anmälda') {
                this.registeredGroup = this.groups[group];
              }
              if (this.groups[group].persons.length === 0 && this.groups[group].groupName !== 'Anmälda') {
                this.groups[group].persons.push({ name: '', mail: '', controlIcon: 'mdi-help' });
              }
            }
            this.groups[group].groupType = 'created';
          }
          axios.get(`${process.env.VUE_APP_BACKENDURL}/api/group/initiate`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((depotResponse) => {
              for (const depot in depotResponse.data.depots) { // eslint-disable-line guard-for-in
                const currGroup = depotResponse.data.depots[depot];
                currGroup.groupType = 'depot';
                this.groups.push(currGroup);
              }
              axios.get(`${process.env.VUE_APP_BACKENDURL}/api/group/groupInformation`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
                .then((groupResponse) => {
                  for (const depot in groupResponse.data) { // eslint-disable-line guard-for-in
                    const currGroup = groupResponse.data[depot];
                    const objIndex = this.groups.findIndex((obj) => obj.groupName === currGroup.groupNameIdentifier); // eslint-disable-line max-len
                    this.groups[objIndex].groupName = currGroup.groupNameDisplay;
                    this.groups[objIndex].groupNameIdentifier = currGroup.groupNameIdentifier;
                  }
                  // this.groupsUtskick = this.groups.filter((group) =>
                  // group.groupName !== 'Anmälda');
                  // Gå igenom personer och lägg in ett fält för depots.
                  console.log('asd');
                  for (const person // eslint-disable-line guard-for-in
                    in this.groups[0].persons) {
                    const tempDepots = this.groups[0].persons[person].depots;
                    // console.log(this.groups[0].persons[person].depots.length);
                    let depCount = 0;
                    for (const depot in tempDepots) { // eslint-disable-line guard-for-in
                      if (tempDepots[depot].depotChosen) {
                        depCount += 1;
                      }
                    }
                    this.groups[0].persons[person].depotSendListCount = depCount;
                    console.log(this.groups[0].persons[person].depotSendListCount);
                  }
                  this.loaderDialog = false;
                  this.groupsUtskick = this.groups;
                });
            })
            .catch((err) => {
              this.alertApprovedApi = true;
              this.errorMessage = err.response.data.message;
            });
        });
    },
    removeSinglePerson(index) {
      this.editedIndex = this.currentGroup[0].persons.indexOf(index);
      this.editedPerson = ({ ...index });
      const token = localStorage.getItem('jwt');
      axios.delete(`${process.env.VUE_APP_BACKENDURL}/api/group/deletePerson/${this.editedPerson.personId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        console.log(response);
        this.currentGroup[0].persons.splice(index, 1);
      });
    },
    initateProducts() {
      const token = localStorage.getItem('jwt');
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/product`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.products = response.data;
        console.log(this.products);
      });
    },
    initiateTemplateList() {
      const token = localStorage.getItem('jwt');
      this.templateList = [];
      axios.get(`${process.env.VUE_APP_BACKENDURL}/api/template`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then((response) => {
        this.templateList = response.data;
        console.log(this.templateList);
      });
    },
    testing(value) {
      console.log(value);
      this.showMenu = true;
    },
    onCmReady(cm) {
    },
    onCmFocus(cm) {
      this.cm = cm;
      console.log('the editor is focused!', cm);
      console.log(this.snippets);
      const test = this.snippets;
      console.log(test);
      const options = {
        hint: function () { // eslint-disable-line
          return {
            from: cm.getDoc().getCursor(),
            to: cm.getDoc().getCursor(),
            list: test,
          };
        },
      };
      console.log('the editor is readied!', cm);
      cm.on('keypress', (_, keyEvent) => {
        if (keyEvent.key === '$') {
          console.log(keyEvent);
          cm.showHint(options);
        }
      });
    },
    onCmCodeChange(newCode) {
      console.log('this is new code', newCode);
      this.code = newCode;
      this.code = this.code.replace('$', '');
    },
    dynamicSort(property) {
      let sortOrder = 1;
      if (property[0] === '-') {
        sortOrder = -1;
        property = property.substr(1); // eslint-disable-line
      }
      return function (a, b) { // eslint-disable-line
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0; // eslint-disable-line
        return result * sortOrder;
      };
    },
  },
  mounted() {
    this.loaderDialog = true;
    const user = JSON.parse(localStorage.getItem('user'));
    this.role = user.role;
    this.initiateBackground();
    this.initiateContent();
    this.initiateIcon();
    this.initiateSendCode();
    this.initateProducts();
    this.initiateGroups();
    this.initiateMails();
    this.initiateTemplateList();
    this.initiateDepotFooters();
    console.log('the current CodeMirror instance object:', this.codemirror);
  },
  components: {
    datetime: Datetime,
    DatePicker,
  },
  computed: {
    sortedList() {
      return this.products.sort(this.dynamicSort('name'));
    },
    sortedGroups() {
      return this.groupsUtskick.sort(this.dynamicSort('groupName'));
    },
    activeCaseList() {
      const caseList = this.history.filter((template) => template.active);
      caseList.push({ templateType: 'reset', subject: 'reset', serialNumber: '' });
      console.log(caseList);
      return caseList;
    },
  },
};
